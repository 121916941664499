






































import { Component, Vue } from 'vue-property-decorator'
import routeNames from '@/constants/routeNames'
import BackDashboardButton from '@/components/BackDashboardButton.vue'
import Success from '@/components/Success.vue'
import AonButton from '@/components/AonButton.vue'
import { Config } from '@/config'
import { Getter } from 'vuex-class'
import { PolicyModel } from 'shift-policy-service-api-client'

@Component({
  components: { BackDashboardButton, Success, AonButton }
})
export default class Completed extends Vue {
  @Getter('policy/convertedPolicy')
  private convertedPolicy!: PolicyModel

  POLICY_CERTIFICATE = routeNames.POLICY_CERTIFICATE
  DASHBOARD = routeNames.DASHBOARD

  viewCert() {
    this.$router.push({
      name: this.POLICY_CERTIFICATE,
      params: { id: this.$route.params.id || this.convertedPolicy.id },
      query: { completed: 'true' }
    })
  }

  getSurveyLink() {
    return Config.surveyLink
  }
}
